import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Button } from 'lynkco-up-core';

import { loginRequest } from '../../config';
import { useLogin } from '../../hooks';
import { routes } from '../../routing';

export default function LoginPage() {
  const { tryLogin, isAuthenticated } = useLogin(loginRequest);
  const navigate = useNavigate();

  const handleLogin = () => {
    tryLogin();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.home.path);
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="w-screen h-screen flex flex-row-reverse bg-black overflow-hidden">
      <Helmet>
        <title>Sign in - LYNK & CO</title>
      </Helmet>
      <div
        className="bg-white left-0 top-0 basis-0 lg:basis-2/3 relative saturate-200"
        style={{
          backgroundImage: 'url(/assets/images/lynkco-car.png)',
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}>
        <div className="absolute left-0 top-0 w-full h-full bg-teal-900 opacity-10"></div>
      </div>
      <div className="basis-full relative p-6 lg:basis-1/3">
        <div className="w-full h-full z-20">
          <div className="absolute top-0 left-2/3 w-7/12 h-full bg-black -skew-x-12 z-0"></div>
          <div className="relative">
            <div>
              <svg width="139" height="20" viewBox="0 0 139 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M71.0368 0.666626C67.28 4.77774 63.6371 8.88885 59.8804 13C59.8804 15.1111 59.8804 17.2222 59.8804 19.2222C61.0188 19.2222 62.0434 19.2222 63.1818 19.2222V13.7777C63.9787 12.8888 64.7755 12 65.5724 11.2222C65.6863 11.3333 69.5569 16.7777 71.3783 19.2222H75.3628C72.8583 15.7777 70.3538 12.2222 67.8492 8.77774C70.0122 6.33329 74.9074 0.888848 75.0212 0.666626H71.0368Z"
                  fill="white"
                />
                <path d="M3.30139 0.666626H0V19.2222H12.4087V16.2222H3.30139V0.666626Z" fill="white" />
                <path
                  d="M15.3687 0.666653C15.4825 0.888875 24.1344 13.7778 24.1344 13.7778V19.2222H27.5497C27.5497 19 27.5497 13.5555 27.5497 12.8889C27.5497 12.8889 19.1254 0.666653 19.1254 0.555542C17.8732 0.666653 16.6209 0.666653 15.3687 0.666653Z"
                  fill="white"
                />
                <path
                  d="M48.8378 12.3333C44.6257 8.22222 40.4135 4.11111 36.2014 0V19.2222H39.5028V7.55556C43.7149 11.6667 47.9271 15.7778 52.1392 19.8889V0.666667H48.7239V12.3333"
                  fill="white"
                />
                <path
                  d="M109.174 16.2222C108.832 16.3333 108.604 16.3333 108.263 16.3333C108.035 16.3333 107.466 16.3333 106.783 16.2222C105.644 16 104.506 15.4444 103.709 14.6666C103.026 14 102.343 13.1111 102.002 12.2222C101.546 11 101.319 9.33329 102.002 7.66662C102.685 5.88884 104.051 4.4444 105.872 3.88884C106.555 3.66662 107.238 3.55551 107.921 3.55551C109.401 3.55551 110.767 4.11107 111.906 4.99996C112.589 4.22218 113.386 3.33329 114.069 2.55551C110.767 -0.222267 106.214 -0.333378 102.798 1.88884C99.9524 3.66662 98.3586 6.66662 98.3586 9.99996C98.3586 14.3333 101.319 18.2222 105.531 19.3333C109.174 20.2222 113.044 19 115.321 16C114.524 15.3333 113.613 14.6666 112.816 14C111.906 15.1111 110.653 15.8888 109.174 16.2222Z"
                  fill="white"
                />
                <path
                  d="M89.9345 16.4445C90.7314 15.6667 91.5283 14.8889 92.2114 14.1112H89.4792C89.1377 14.4445 88.91 14.6667 88.5685 15C87.7716 14.1112 86.9747 13.2223 86.1778 12.3334C86.2916 12.2223 86.5193 12.1112 86.6331 11.8889C87.2024 11.4445 87.6577 11 88.1131 10.3334C88.3408 9.77783 88.6823 8.88894 88.3408 7.88894C88.1131 6.88894 87.3162 6.11116 86.4055 5.66672C84.4702 4.77783 82.1933 5.88894 81.738 7.88894C81.6241 8.44449 81.6241 9.11116 81.738 9.77783C81.9657 10 82.0795 10.3334 82.1933 10.5556C82.5349 11.1112 82.9902 11.5556 83.4456 12.1112C82.8764 12.5556 82.1933 13 81.8518 13.5556C81.738 13.7778 81.5103 14 81.2826 14.4445C81.0549 14.8889 81.0549 15.4445 81.0549 16C81.1688 17.1112 81.738 18 82.5349 18.6667C83.2179 19.1112 83.901 19.4445 84.6979 19.5556C85.1532 19.5556 85.4947 19.5556 85.7224 19.5556C85.9501 19.5556 86.0639 19.4445 86.2916 19.4445C86.6332 19.3334 87.0885 19.1112 87.43 18.8889C87.7716 18.6667 88.1131 18.2223 88.5685 17.8889C89.0238 18.3334 89.4792 18.8889 89.8207 19.3334H92.439C91.6422 18.3334 90.7314 17.3334 89.9345 16.4445ZM83.5594 8.11116C83.6733 7.88894 83.7871 7.66672 84.0148 7.44449C84.3563 7.22227 84.6979 7.11116 85.1532 7.11116C86.0639 7.22227 86.6331 7.88894 86.6331 8.66672C86.6331 9.33338 86.1778 9.66672 85.9501 9.88894C85.8363 10 85.7224 10.1112 85.6086 10.2223C85.4947 10.3334 85.1532 10.5556 84.8117 10.8889C84.3563 10.4445 84.0148 10 83.7871 9.66672C83.6733 9.22227 83.4456 8.66672 83.5594 8.11116ZM86.9747 16.4445C86.747 16.6667 86.4055 16.8889 86.1778 17.1112C86.0639 17.2223 85.8363 17.3334 85.8363 17.3334C85.7224 17.3334 85.7224 17.4445 85.6086 17.4445C85.4947 17.4445 85.2671 17.5556 85.1532 17.5556C84.9255 17.5556 84.584 17.5556 84.2425 17.3334C83.5594 17 83.1041 16.4445 83.1041 15.7778C83.1041 15.4445 83.1041 15.2223 83.2179 15C83.2179 14.8889 83.3318 14.7778 83.4456 14.6667C83.6733 14.2223 84.1286 13.8889 84.8117 13.4445C85.6086 14.3334 86.5193 15.3334 87.3162 16.2223C87.0885 16.3334 87.0885 16.4445 86.9747 16.4445Z"
                  fill="white"
                />
                <path
                  d="M123.745 11.1111C122.607 11.1111 121.582 11.1111 120.444 11.1111C120.672 13.3333 121.696 15.3333 123.29 16.8888C124.201 17.7777 125.111 18.2222 125.453 18.4444C125.908 18.6666 126.364 18.8888 126.819 18.9999C127.616 19.2222 128.64 19.4444 129.893 19.4444C131.259 19.4444 132.966 19.1111 134.674 17.8888C136.495 16.6666 137.862 14.7777 138.545 12.6666C139.341 9.88883 139 6.33328 136.723 3.66661C135.016 1.5555 132.397 0.222168 129.665 0.222168C127.274 0.222168 124.997 1.11106 123.29 2.77772C124.087 3.44439 124.997 4.11106 125.794 4.88883C127.388 3.5555 129.21 3.33328 130.576 3.5555C131.714 3.77772 132.853 4.33328 133.649 5.11106C134.902 6.33328 135.585 7.99995 135.585 9.77772C135.585 11.6666 134.788 13.5555 133.194 14.8888C132.397 15.5555 131.486 15.8888 130.576 16.1111C129.551 16.3333 128.413 16.2222 127.388 15.7777C125.567 15.1111 124.087 13.3333 123.745 11.1111Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="mt-36">
              <h2 className="text-white text-6xl font-bold">
                Internal apps for <br /> managing <span className="text-primary-700">products</span> and <br />
                <span className="text-primary-700">services</span>
              </h2>
            </div>
            <div className="mt-5">
              <p className=" text-gray-400 font-normal text-2xl">
                Sign in to manage different products and services in the
              </p>
              <p className=" text-gray-400 font-normal text-2xl">LYNK & CO eco-systems</p>
            </div>
            <div className="mt-20">
              <Button onClick={handleLogin} variant="white">
                <div className="flex justify-start">
                  <img alt="Microsoft logo" width={20} src="/assets/images/Microsoft-logo.svg"></img>

                  <p className="pl-4">Sign in with Microsoft account </p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
