import { AxiosError } from 'axios';
import { serviceConfig } from '../../config';
import { connectedServiceClient } from '../shared';

const endpointConnectedService = '/gdpr-manager/api/v1/admin/gdpr/user-data?customerNumber=';

async function getUserDataAsFile(caseId: string, customerNumber: string): Promise<Blob> {
  const url = `${serviceConfig.endpoints.gdpr.root}${endpointConnectedService}${customerNumber}&format=XLSX`;

  try {
    const response = await connectedServiceClient.get(url, {
      responseType: 'blob',
      method: 'GET',
    });

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }

    throw new Error('Failed to get user data');
  }
}

export default getUserDataAsFile;
