import axios from 'axios';
import getTokenFromServiceClient from './getTokenForServiceClient';

const connectedServiceClient = axios.create();

connectedServiceClient.interceptors.request.use(
  async config => {
    const token = await getTokenFromServiceClient();

    if (!token) {
      throw new Error('Failed to get token');
    }

    if (!config.headers) {
      config.headers = {};
    }

    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default connectedServiceClient;
