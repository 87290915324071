import { carSharingRequest } from '../../config/auth';
import msalInstance from './auth';

/**
 * Attempts to get a token silently and if silent fails attempts using popup.
 *
 * @returns `[token, error]: [string | null, unknown]`
 */
async function getTokenForCarSharing(): Promise<string | null> {
  let token = null;

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...carSharingRequest,
      account: msalInstance.getAllAccounts()[0],
    });
    token = response.accessToken;
  } catch (err) {
    if (err) {
      try {
        const response = await msalInstance.acquireTokenPopup(carSharingRequest);
        token = response.accessToken;
      } catch (err) {
        /** Something went wrong and all token acquisitions failed, clear storage and redirect to login */
        window.sessionStorage.clear();
        window.location.replace('/');
      }
    }
  }

  return token;
}

export default getTokenForCarSharing;
