import { useContext, useEffect, useRef, useState } from 'react';

import { Alert, Button, GlobalContext, Icon, Input } from 'lynkco-up-core';
import { GenericObject, ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { getUserData, getUserDataAsFile } from '../../../services/GDPR';
import { Divider } from '../../../components';
import { InputWrapper } from '../../../parts/shared';

function GetUserDataContent() {
  const { alert, loading } = useContext(GlobalContext);
  const [customerNumber, setCustomerNumber] = useState('');
  const [caseId, setCaseId] = useState('');
  const [formErrors, setFormErrors] = useState({
    customerNumber: false,
    caseId: false,
  });
  const maxLengthErrorMessage = 'This value cannot exceed 50 characters.';
  const isSubmitDisabled = !customerNumber || !caseId || Object.values(formErrors).some(error => error);
  const isDownloadDisabled = !customerNumber || Object.values(formErrors).some(error => error);
  const [resultError, setResultError] = useState<string | null>(null);
  const [userData, setUserData] = useState<null | GenericObject>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeCustomerNumber: ReactInputEventHandler = e => {
    setCustomerNumber(e.currentTarget.value);
  };

  const handleChangeCaseId: ReactInputEventHandler = e => {
    setCaseId(e.currentTarget.value);
  };

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }

    if (resultError) {
      setResultError(null);
    }

    loading.show();

    try {
      const data = await getUserData(caseId, customerNumber);
      setUserData(data);
      alert.show('Data successfully retrieved.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  async function handleDownload() {
    if (isDownloadDisabled) {
      return;
    }

    if (resultError) {
      setResultError(null);
    }

    loading.show();

    try {
      const data = await getUserDataAsFile(caseId, customerNumber);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
      alert.show('Data successfully retrieved.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  function handleDismissResultError() {
    setResultError(null);
  }

  async function copyAllToClipboard() {
    try {
      const value = JSON.stringify(userData, null, '\t');

      await navigator.clipboard.writeText(value);
      alert.show('User data copied to clipboard', 'success');
    } catch (error) {
      alert.show('Error copying to clipboard.', 'error');
    }
  }

  function handleClickCopyData() {
    copyAllToClipboard();
  }

  useEffect(() => {
    setFormErrors({
      customerNumber: customerNumber.length > 50,
      caseId: caseId.length > 50,
    });
  }, [customerNumber, caseId]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  return (
    <div>
      <h2 className="font-medium text-xl mb-7">Get user data</h2>
      {resultError && (
        <Alert
          type="error"
          heading="There was an error while trying to get user data"
          body={`${resultError}`}
          onDismiss={handleDismissResultError}
        />
      )}
      <Divider />
      <InputWrapper label="Customer number*">
        <Input
          ref={inputRef}
          type="text"
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCustomerNumber}
          errorText={maxLengthErrorMessage}
          hasError={formErrors.customerNumber}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper label="Case ID">
        <Input
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCaseId}
          errorText={maxLengthErrorMessage}
          hasError={formErrors.caseId}
        />
      </InputWrapper>
      <Divider />
      <div className="flex justify-end mt-7">
        <Button extraClasses="mr-3" onClick={() => handleDownload()} isDisabled={isDownloadDisabled}>
          Extract from Connected Services
        </Button>
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitDisabled}>
          Extract from Car Sharing
        </Button>
      </div>
      {userData && (
        <div className="mt-10">
          <div className="flex justify-between items-center">
            <h3>Results</h3>
            <Button onClick={handleClickCopyData} size="small" variant="white">
              Copy <Icon name="content_copy" size="small" padding={0} />
            </Button>
          </div>
          <div className="mt-5 border rounded-md">
            <pre className="break-all whitespace-pre-wrap text-s p-2">{JSON.stringify(userData, null, '\t')}</pre>
          </div>
        </div>
      )}
    </div>
  );
}

export default GetUserDataContent;
