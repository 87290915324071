import { GenericObject } from 'lynkco-up-core/dist/types/types';
import { serviceConfig } from '../../config';
import { AxiosError } from 'axios';
import { Endpoint } from '../../types';
import { carSharingClient, connectedServiceClient } from '../shared';

const endpointCarSharing = '/applicant';
const endpointConnectedService = '/gdpr-manager/api/v1/admin/gdpr/user-data?customerNumber=';

async function deleteUserData(caseId: string, customerNumber: string, endpoint: Endpoint): Promise<GenericObject> {
  try {
    let response;

    if (Endpoint.CAR_SHARING == endpoint) {
      response = await deleteForCarsharing(caseId, customerNumber);
    }
    if (Endpoint.CONNECTED_SERVICE == endpoint) {
      response = await deleteForConnectedServices(caseId, customerNumber);
    }

    return response?.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }

    throw new Error('Failed to delete user data');
  }
}

function deleteForCarsharing(caseId: string, customerNumber: string) {
  const url = `${serviceConfig.endpoints.onfido.root}${endpointCarSharing}`;

  return carSharingClient.delete(url, {
    method: 'DELETE',
    headers: {
      'X-CustomerNumber': customerNumber,
    },
    data: {
      caseId,
    },
  });
}

function deleteForConnectedServices(caseId: string, customerNumber: string) {
  const url = `${serviceConfig.endpoints.gdpr.root}${endpointConnectedService}${customerNumber}`;

  return connectedServiceClient.delete(url, {
    method: 'DELETE',
  });
}

export default deleteUserData;
