import { AxiosError } from 'axios';

import { GenericObject } from 'lynkco-up-core/dist/types/types';

import { carSharingClient } from '../shared';
import { serviceConfig } from '../../config';

const endpointUrl = '/update-verification-status';
const onfidoVerificationStatus = 'FINISHED_SUCCESSFULLY_MANUALLY_UPDATED';

async function approveDriversLicense(caseId: string, customerNumber: string, reason: string): Promise<GenericObject> {
  const url = `${serviceConfig.endpoints.onfido.root}${endpointUrl}`;

  try {
    const response = await carSharingClient.post(
      url,
      {
        caseId,
        reason,
        onfidoVerificationStatus,
      },
      {
        headers: {
          'X-CustomerNumber': customerNumber,
        },
      },
    );

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }

    throw new Error('Failed to manually approve drivers license');
  }
}

export default approveDriversLicense;
