export default {
  endpoints: {
    onfido: {
      root: process.env.REACT_APP_ONFIDO_SERVICE_ROOT,
    },
    gdpr: {
      root: process.env.REACT_APP_GDPR_MANAGER_ROOT,
    },
  },
};
